import { HTTP } from "@/plugins/http";
const state = {
  tesolaround: [],
};
const getters = {
  tesolaroundList: (state) => state.tesolaround,
};
const actions = {
  async fetchTesolaround({ commit }) {
    const response = await HTTP.get("/tesolaround");
    commit("setTesolaround", response.data.data); //remove the 2nd data in json server
  },
};
const mutations = {
  setTesolaround: (state, tesolaround) => (state.tesolaround = tesolaround),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
