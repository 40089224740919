import { HTTP } from "@/plugins/http";
const state = {
  news: [],
};
const getters = {
  newsList: (state) => state.news,
};
const actions = {
  async fetchNews({ commit }) {
    const response = await HTTP.get("/news", {
      params: { filter: 4 }, //pass 4 for all news, 1: last day, 2: last week, 3: last month
    });
    commit("setNews", response.data.data); //remove the 2nd data in json server
  },
};
const mutations = {
  setNews: (state, news) => (state.news = news),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
