import { HTTP } from "@/plugins/http";
const state = {
  members: [],
};
const getters = {
  membersList: (state) => state.members,
};
const actions = {
  async fetchMembers({ commit }) {
    await HTTP.get("/team", {
      params: { type: "Member" },
    })
      .then((response) => {
        //commit("setMembers", response.data);//from json server
        commit("setMembers", response.data.data); //from real api
      })
      .catch((error) => {
        console.log("err", error);
      });
  },
  async addMembers({ commit }, members) {
    const response = await HTTP.post("/members", members);
    commit("addNewMembers", response.data);
  },
  async deleteMembers({ commit }, id) {
    await HTTP.delete(`/members/${id}`);
    commit("removeMembers", id);
  },
};
const mutations = {
  setMembers: (state, members) => (state.members = members),
  addNewMembers: (state, members) => state.members.unshift(members),
  removeMembers: (state, id) => (
    state.members.filter((members) => members.id !== id),
    state.members.splice((members) => members.id, 1)
  ),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
