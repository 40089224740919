import { HTTP } from "@/plugins/http";
const state = {
  offers: [],
};
const getters = {
  offersList: (state) => state.offers,
};
const actions = {
  async fetchOffers({ commit }) {
    const response = await HTTP.get("/offers");
    commit("setOffers", response.data.data); //remove the 2nd data in json server
  },
  async addOffers({ commit }, offer) {
    const response = await HTTP.post("/offers", offer);
    commit("addNewOffer", response.data);
  },
  async deleteOffer({ commit }, id) {
    await HTTP.delete(`/offers/${id}`);
    commit("removeOffer", id);
  },
};
const mutations = {
  setOffers: (state, offers) => (state.offers = offers),
  addNewOffer: (state, offer) => state.offers.unshift(offer),
  removeOffer: (state, id) => (
    state.offers.filter((offer) => offer.id !== id),
    state.offers.splice((offer) => offer.id, 1)
  ),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
