import { toast, toastError } from "@/components/MessageDialog";
import { HTTP } from "@/plugins/http";
const state = {
  form: [],
};
const getters = {
  FormList: (state) => state.form,
};
const actions = {
  // async fetchForm({ commit }) {
  //   const response = await HTTP.get("/memberapp");
  //   commit("setForm", response.data.data); //remove the 2nd data in json server
  // },
  async submit({ commit }, obj) {
    const formData = new FormData();
    formData.append("firstname", obj.firstname);
    formData.append("lastname", obj.lastname);
    formData.append("education", obj.education);
    formData.append("current_work", obj.current_work);
    formData.append("work_location", obj.work_location);
    formData.append("phonenumber", obj.phonenumber);
    formData.append("email", obj.email);

    // const response = await HTTP.post("/memberapp", formData);
    // commit("setForm", response); //remove the 2nd data in json server

    // commit("loginRequest", { auth });
    await HTTP.post("/memberapp", formData)
      .then((user) => {
        commit("setForm", user.data.data);
        toast(user.data.message);
      })
      .catch((error) => {
        debugger;
        toastError(error.response.data.message, error.response.data.data);
      });
  },
};
const mutations = {
  setForm: (state, form) => (state.form = form),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
