import { HTTP } from "@/plugins/http";
const state = {
  Gallery: [],
};
const getters = {
  GalleryList: (state) => state.Gallery,
};
const actions = {
  async fetchGallery({ commit }) {
    const response = await HTTP.get("/gallery");
    commit("setGallery", response.data.data);
  },
};
const mutations = {
  setGallery: (state, Gallery) => (state.Gallery = Gallery),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
