<template>
  <div>
    <loading-page v-if="isLoading" />

    <div v-else class="home">
      <!-- <home-header /> -->
      <home-carousel />
      <!-- <conference-upcoming /> -->
      <!-- <home-sponsored /> -->
      <!-- <offers-list /> -->
      <!-- <proposal-form /> -->
      <!-- <partners-list /> -->
      <!-- <news-list /> -->
      <subscribe-form />
      <books-list />
      <contact-section />
      <main-footer />
    </div>
  </div>
</template>

<script>
// import HomeHeader from "../components/Home/HomeHeader";
// import ConferenceUpcoming from "../components/Home/ConferenceUpcoming";
// import HomeSponsored from "../components/Home/HomeSponsored.vue";
// import OffersList from "../components/Home/OffersList.vue";
// import ProposalForm from "@/components/Home/ProposalForm.vue";
// import PartnersList from "@/components/Home/PartnersList.vue";
// import NewsList from "@/components/Home/NewsList.vue";
import SubscribeForm from "@/components/Home/SubscribeForm.vue";
import BooksList from "@/components/Home/BooksList.vue";
import ContactSection from "@/components/Home/ContactSection.vue";
import HomeCarousel from "../components/Home/HomeCarousel.vue";
import MainFooter from "../components/MainFooter.vue";
import LoadingPage from "@/components/LoadingPage.vue";

export default {
  name: "HomeView",

  components: {
    // HomeHeader,
    // ConferenceUpcoming,
    // HomeSponsored,
    // OffersList,
    // ProposalForm,
    // PartnersList,
    // NewsList,
    SubscribeForm,
    BooksList,
    ContactSection,
    HomeCarousel,
    MainFooter,
    LoadingPage,
  },
  computed: {
    isLoading: {
      get() {
        return this.loading;
      },
      set(newValue) {
        if (newValue) {
          this.doAsyncTask();
        }
      },
    },
  },
  async mounted() {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.loading = false;
  },
  data() {
    return {
      loading: true,
    };
  },
};
</script>

<style>
@import "../assets/css/style.css";
</style>
