<template>
  <div>
    <v-carousel interval="6000" hide-delimiters height="60vh">
      <v-carousel-item
        v-for="photo in photosList"
        :key="photo.id"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-img :src="`${path}/storage/${photo.image}`" class="fill-height">
          <v-container fill-height fluid pa-0 ma-0 class="d-none d-md-block">
            <v-layout fill-height align-end>
              <v-flex xs12>
                <v-card rounded="0" color="#1b295b" class="pa-2 py-6">
                  <p class="headline text-center white--text px-6 ma-0">
                    {{ photo.text }}
                  </p>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HomeCarousel",
  methods: {
    ...mapActions(["fetchPhotos"]),
  },
  computed: {
    ...mapGetters(["photosList"]),
    path: () => process.env.VUE_APP_URL,
  },
  created() {
    this.fetchPhotos();
  },
  // data() {
  //   return {
  //     path: "assets/",
  //   };
  // },
  // mounted() {
  //   this.path = process.env.VUE_APP_URL;
  // },
};
</script>
