import { HTTP } from "@/plugins/http";
const state = {
  abouts: [],
};
const getters = {
  aboutsList: (state) => state.abouts,
};
const actions = {
  async fetchAbouts({ commit }) {
    const response = await HTTP.get("/abouts");
    commit("setAbouts", response.data.data); //remove the 2nd data in json server
  },
  async addAbouts({ commit }, abouts) {
    const response = await HTTP.post("/abouts", abouts);
    commit("addNewAbouts", response.data);
  },
  async deleteAbouts({ commit }, id) {
    await HTTP.delete(`/abouts/${id}`);
    commit("removeAbouts", id);
  },
};
const mutations = {
  setAbouts: (state, abouts) => (state.abouts = abouts),
  addNewAbouts: (state, abouts) => state.abouts.unshift(abouts),
  removeAbouts: (state, id) => (
    state.abouts.filter((abouts) => abouts.id !== id),
    state.abouts.splice((abouts) => abouts.id, 1)
  ),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
