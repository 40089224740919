import { HTTP } from "@/plugins/http";
const state = {
  partners: [],
};
const getters = {
  partnersList: (state) => state.partners,
};
const actions = {
  async fetchPartners({ commit }) {
    const response = await HTTP.get("/partner");
    commit("setPartners", response.data.data); //remove the 2nd data in json server
  },
  async addPartners({ commit }, partner) {
    const response = await HTTP.post("/partner", partner);
    commit("addNewPartner", response.data);
  },
  async deletepartner({ commit }, id) {
    await HTTP.delete(`/partners/${id}`);
    commit("removePartner", id);
  },
};
const mutations = {
  setPartners: (state, partners) => (state.partners = partners),
  addNewPartner: (state, partner) => state.partners.unshift(partner),
  removePartner: (state, id) => (
    state.partners.filter((partner) => partner.id !== id),
    state.partners.splice((partner) => partner.id, 1)
  ),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
