import { HTTP } from "@/plugins/http";
const state = {
  NewsArticles: [],
};
const getters = {
  NewsArticlesList: (state) => state.NewsArticles,
};
const actions = {
  async fetchNewsArticles({ commit }) {
    const response = await HTTP.get("/newsarticles");
    commit("setNewsArticles", response.data.data);
  },
};
const mutations = {
  setNewsArticles: (state, NewsArticles) => (state.NewsArticles = NewsArticles),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
