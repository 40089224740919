<template>
  <v-row class="px-8 mx-auto justify-center align-start my-16">
    <v-col
      cols="12"
      md="6"
      lg="4"
      sm="12"
      v-for="book in booksList"
      :key="book.id"
      class="d-flex align-center justify-center pb-16 px-md-6"
    >
      <book-card
        :bookLink="book.link"
        :title="book.title"
        :description="book.description"
        :image="book.image"
      />
    </v-col>
  </v-row>
</template>

<script>
import BookCard from "@/components/Home/BookCard.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "BooksList",
  methods: {
    ...mapActions(["fetchBooks", "deleteBooks"]),
  },
  computed: mapGetters(["booksList"]),
  created() {
    this.fetchBooks();
  },
  components: {
    BookCard,
  },
  mounted() {
    console.log("books", this.booksList);
  },
};
</script>

<style>
@import "@/assets/css/style.css";
</style>
