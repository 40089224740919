<template>
  <div
    class="form d-flex flex-column flex-md-row justify-space-around align-center mt-16"
  >
    <div class="text py-6">
      <p class="mb-0 white_o">Subscribe To Our NewsLetter</p>
    </div>
    <v-card elevation-0 rounded="lg" width="40%" class="my-5">
      <v-text-field
        v-model="email"
        height="30"
        class="white rounded-lg px-8 pt-4 my-auto"
        label="Enter Your Email Address*"
        required
      >
      </v-text-field>
    </v-card>
    <div class="btn my-4">
      <v-btn
        class="rounded-lg blue_o"
        color="white"
        elevation="2"
        large
        @click="send"
      >
        Subscribe
      </v-btn>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/plugins/http";
export default {
  name: "SubscribeForm",
  data: () => ({
    email: null,
  }),
  methods: {
    send() {
      if (this.email === null) {
        alert("email is required!");
      } else {
        HTTP.post("/subscriber", {
          email: this.email,
        })
          .then(() => {
            //console.log("res", res);
            alert("your email has beeen added to our list");
            this.email = null;
          })
          .catch(() => {
            //console.log(error);
          });
      }
    },
  },
};
</script>

<style>
@import "@/assets/css/style.css";
.v-text-field__details {
  display: none !important;
}
</style>
