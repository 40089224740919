<template>
  <v-row class="ma-0 mx-12">
    <v-col class="d-flex align-center">
      <v-card elevation="0" rounded="0" width="100%" class="contact">
        <div class="text">
          <h1 class="blue_o text-h3 py-10 font-weight-bold">Contact</h1>
        </div>
        <v-form>
          <v-text-field
            v-model="fullname"
            label="Full name"
            required
            class="pr-md-16 mr-md-16 blue_o"
          ></v-text-field>
          <v-text-field
            v-model="email"
            label="E-mail"
            required
            class="pr-md-16 mr-md-16 blue_o"
          ></v-text-field>
          <v-textarea
            v-model="msg"
            outlined
            label="Message"
            class="pr-md-16 mr-md-16 blue_o"
          >
            Message
          </v-textarea>
          <v-btn dark class="blue_oo" elevation="3" large @click="send"
            >Send</v-btn
          >
        </v-form>
      </v-card>
    </v-col>
    <v-col class="d-flex align-center justify-center py-16 flex-column">
      <v-row class="flex-column ma-0">
        <v-col>
          <v-row class="justify-space-around">
            <v-col
              cols="12"
              md="4"
              sm="4"
              class="d-flex flex-column align-center justify-space-between p-0"
            >
              <v-card
                elevation="0"
                rounded="0"
                height="35%"
                class="img_container d-flex"
              >
                <img src="@/assets/images/Home/location.svg" alt="" />
              </v-card>
              <v-card
                elevation="0"
                rounded="0"
                height="65%"
                class="d-flex align-center jusify-center"
              >
                <h5 class="text__cont text-center blue_o">
                  01 Fayez Mansour, Damascus, Syria
                </h5>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="4"
              class="d-flex flex-column align-center justify-space-between p-0"
            >
              <v-card
                elevation="0"
                rounded="0"
                height="35%"
                class="img_container d-flex"
              >
                <img src="@/assets/images/Home/mail.svg" alt="" />
              </v-card>
              <v-card
                elevation="0"
                rounded="0"
                height="65%"
                class="d-flex align-center jusify-center"
              >
                <h5 class="text__cont text-center blue_o">
                  Info@blueocean.school
                </h5>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="4"
              class="d-flex flex-column align-center justify-space-between p-0"
            >
              <v-card
                elevation="0"
                rounded="0"
                height="35%"
                class="img_container d-flex"
              >
                <img src="@/assets/images/Home/phone.svg" alt="" />
              </v-card>
              <v-card
                elevation="0"
                rounded="0"
                height="65%"
                class="d-flex align-center justify-end flex-column"
              >
                <h5 class="text__cont text-center blue_o pb-3">
                  +963 944 007 069
                </h5>
                <h5 class="text__cont text-center blue_o">+963 11 7069</h5>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-card
            elevation="0"
            rounded="0"
            width="100%"
            class="contact_map d-flex justify-center align-center"
          >
            <v-card
              elevation="0"
              rounded="0"
              width="100%"
              class="img_container"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3327.328834041463!2d36.240516575699516!3d33.492820747062176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1518df17f39ae527%3A0x9193af4f7c04f1f2!2sBlue%20Ocean%20School!5e0!3m2!1sen!2s!4v1687158374581!5m2!1sen!2s"
                width="650"
                height="450"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                class="map"
              ></iframe>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <!-- <div class="contact_info"></div> -->
    </v-col>
  </v-row>
</template>

<style>
@media (max-width: 768px) {
  .map {
    max-width: 380px;
  }
}
</style>

<script>
import { HTTP } from "@/plugins/http";
export default {
  name: "ContactSection",
  data: () => ({
    fullname: null,
    email: null,
    msg: null,
  }),
  methods: {
    send() {
      if (this.fullname === null) {
        alert("We need to know your name!");
      } else if (this.email === null) {
        alert("We need your email!");
      } else if (this.msg === null) {
        alert("You can't send an empty message!");
      } else {
        HTTP.post("/message", {
          fullname: this.fullname,
          email: this.email,
          message: this.msg,
        })
          .then(() => {
            alert("your message has been sent");
            this.fullname = null;
            this.email = null;
            this.msg = null;
          })
          .catch(() => {});
      }
    },
  },
};
</script>
