<template>
  <div>
    <v-card
      elevation="0"
      rounded="0"
      width="100vw"
      class="loader_bg d-flex align-center justify-center"
    >
      <v-card
        elevation="0"
        rounded="0"
        width="50vw"
        height="50vh"
        class="transparent d-flex align-center justify-center"
      >
        <v-progress-circular color="white" indeterminate size="128" :width="4">
        </v-progress-circular>
      </v-card>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "LoadingPage",
};
</script>

<style>
@import "../assets/css/style.css";
</style>
