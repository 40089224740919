import { HTTP } from "@/plugins/http";
const state = {
  conferences: [],
};
const getters = {
  conferencesList: (state) => state.conferences,
};
const actions = {
  async fetchConferences({ commit }) {
    const response = await HTTP.get("/conferences");
    commit("setConferences", response.data.data); //remove the 2nd data in json server
  },
};
const mutations = {
  setConferences: (state, conferences) => (state.conferences = conferences),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
