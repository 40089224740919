import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/members",
    name: "members",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MembersView.vue"),
  },
  {
    path: "/conferences",
    name: "conferences",
    component: () => import("../views/ConferencesView.vue"),
  },
  {
    path: "/lectures/:id",
    name: "lectures",
    component: () => import("../views/LecturesView.vue"),
  },
  {
    path: "/library",
    name: "library",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LibraryView.vue"),
  },
  {
    path: "/newsletter",
    name: "newsletter",
    component: () => import("../views/NewsletterView.vue"),
  },
  {
    path: "/tesolaround",
    name: "tesolaround",
    component: () => import("../views/TesolAroundView.vue"),
  },
  {
    path: "/member",
    name: "member",
    component: () => import("../views/MemberView.vue"),
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("../views/GalleryView.vue"),
  },
  {
    path: "/news/",
    name: "news",
    component: () => import("../views/NewsArticlesView.vue"),
  },
  {
    path: "/newsArticles/:id",
    name: "newsArticles",
    component: () => import("../views/NewsArticlesDetailsView.vue"),
  },
  {
    path: "/newsLetterDetails/:id",
    name: "newsLetterDetails",
    component: () => import("../components/Home/NewsLetterDetails.vue"),
  },
];

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
