import { HTTP } from "@/plugins/http";
const state = {
  directors: [],
};
const getters = {
  directorsList: (state) => state.directors,
};
const actions = {
  async fetchDirectors({ commit }) {
    const response = await HTTP.get("/team", { params: { type: "Director" } });
    commit("setDirectors", response.data.data); //remove the 2nd data in json server
  },
  async addDirectors({ commit }, directors) {
    const response = await HTTP.post("/directors", directors);
    commit("addNewDirectors", response.data);
  },
  async deleteDirectors({ commit }, id) {
    await HTTP.delete(`/directors/${id}`);
    commit("removeDirectors", id);
  },
};
const mutations = {
  setDirectors: (state, directors) => (state.directors = directors),
  addNewDirectors: (state, directors) => state.directors.unshift(directors),
  removeDirectors: (state, id) => (
    state.directors.filter((directors) => directors.id !== id),
    state.directors.splice((directors) => directors.id, 1)
  ),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
