import { HTTP } from "@/plugins/http";
const state = {
  books: [],
};
const getters = {
  booksList: (state) => state.books,
};
const actions = {
  async fetchBooks({ commit }) {
    const response = await HTTP.get("/books");
    commit("setBooks", response.data.data); //remove the 2nd data in json server
  },
  async addBooks({ commit }, book) {
    const response = await HTTP.post("/books", book);
    commit("addNewBook", response.data);
  },
  async deleteBook({ commit }, id) {
    await HTTP.delete(`/books/${id}`);
    commit("removeBook", id);
  },
};
const mutations = {
  setBooks: (state, books) => (state.books = books),
  addNewBook: (state, book) => state.books.unshift(book),
  removeBook: (state, id) => (
    state.books.filter((book) => book.id !== id),
    state.books.splice((book) => book.id, 1)
  ),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
