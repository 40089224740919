<template>
  <div class="footer pt-16">
    <v-row class="py-6">
      <v-col
        class="d-flex align-center justify-center"
        cols="12"
        md="6"
        sm="12"
      >
        <div class="img_container">
          <img src="@/assets/images/Logo-Secondary.svg" alt="blueocean logo" />
        </div>
      </v-col>
      <v-col class="d-flex align-center" cols="12" md="6" sm="12">
        <div class="social d-flex flex-column justify-space-between">
          <div class="text text-md-start text-center text-h4">
            <p>Follow Us :</p>
          </div>
          <div
            class="social_links d-flex align-center justify-space-around pt-10 mx-md-0 mx-auto"
          >
            <a
              v-for="config in configs"
              :key="config.id"
              :href="config.value"
              class="social_di"
              target="_blank"
            >
              <img
                v-if="config.key == 'facebook'"
                class="imggg"
                src="../assets/images/Home/footer/facebook.svg"
                alt=""
              />
              <img
                v-else-if="config.key == 'instagram'"
                class="imggg"
                src="../assets/images/Home/footer/insta.svg"
                alt=""
              />
              <img
                v-else-if="config.key == 'linkedin'"
                class="imggg"
                src="../assets/images/Home/footer/linked.svg"
                alt=""
              />
              <img
                v-else-if="config.key == 'twitter'"
                class="imggg"
                src="../assets/images/Home/footer/twitter.svg"
                alt=""
              />
              <img
                v-else-if="config.key == 'snapchat'"
                class="imggg"
                src="../assets/images/Home/footer/snap.svg"
                alt=""
              />
              <img
                v-else-if="config.key == 'youtube'"
                class="imggg"
                src="../assets/images/Home/footer/youtube.svg"
                alt=""
              />
            </a>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="text_blue d-flex justify-center align-center">
      <p class="text-center mb-0 footer_text px-4">
        Copyright © 2022 TESOL Syria | All Rights Reserved | Powered by aldrtech
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MainFooter",
  computed: mapGetters(["ConfigsList"]),
  methods: {
    ...mapActions(["fetchConfig"]),
  },
  mounted() {
    this.fetchConfig().then((data) => {
      console.log("ssss", data);
      this.configs = data;
    });
  },
  data: () => ({
    configs: Array,
  }),
};
</script>

<style>
@import "@/assets/css/style.css";
.social {
  width: 100%;
}
.img_container {
  width: 20%;
}
.social_links {
  width: 75%;
}
.imggg {
  width: 100% !important;
}
.text_blue {
  background-color: #1b295b;
  height: 7vh;
}
.footer_text {
  color: #fff;
}
</style>
