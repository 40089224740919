import Vue from "vue";
import Vuex from "vuex";
import BooksModule from "../store/modules/books-module";
import OffersModule from "../store/modules/offers-module";
import PartnersModule from "../store/modules/partners.moudule";
import NewsModules from "../store/modules/news-module";
import AboutModules from "../store/modules/about-module";
import DirectorsModule from "../store/modules/directors-module";
import MembersModule from "../store/modules/members-module";
import ConferencesModule from "../store/modules/conferences-module";
import LecturesModule from "../store/modules/lectures-module";
import TesolAroundModule from "../store/modules/tesolaround-module";
import SlideModule from "../store/modules/slide-module";
import GalleryModule from "../store/modules/gallery-module";
import NewsArticlesModule from "./modules/news-articles-module";
import Form from "./modules/form-module";
import Configs from "./modules/config-module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cid: -100,
  },
  getters: {
    cid: (state) => state.cid,
  },
  mutations: {
    setCid: (state, cid) => (state.cid = cid),
  },
  actions: {
    changeCid({ commit }, cid) {
      commit("setCid", cid);
    },
  },
  modules: {
    BooksModule,
    OffersModule,
    PartnersModule,
    NewsModules,
    AboutModules,
    DirectorsModule,
    MembersModule,
    ConferencesModule,
    LecturesModule,
    TesolAroundModule,
    SlideModule,
    GalleryModule,
    NewsArticlesModule,
    Form,
    Configs,
  },
});
