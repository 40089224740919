import { HTTP } from "@/plugins/http";
const state = {
  photos: [],
};
const getters = {
  photosList: (state) => state.photos,
};
const actions = {
  async fetchPhotos({ commit }) {
    const response = await HTTP.get("/imageslider");
    commit("setPhotos", response.data.data); //remove the 2nd data in json server
  },
};
const mutations = {
  setPhotos: (state, photos) => (state.photos = photos),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
