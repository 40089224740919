import Swal from "sweetalert2";
export function toast(text) {
  Swal.fire({
    position: "top-center",
    icon: "success",
    title: text,
    showConfirmButton: false,
    timer: 2500,
  });
}

export function toastError(text, content) {
  Swal.fire({
    position: "top-center",
    icon: "error",
    title: text,
    text: content[0],
    showConfirmButton: false,
    timer: 5000,
  });
}
