<template>
  <v-card class="book_card elevation-0 p-0 px-md-8">
    <a :href="bookLink" target="_blank" class="text-decoration-none">
      <div class="content">
        <div class="d-flex justify-center books_container mx-auto">
          <v-img
            max-height="60vh"
            v-bind:src="`${path}/storage/${image}`"
            class="rounded-lg img_lib_dim"
          />
        </div>
        <div class="title text-center py-8 blue_o">
          <h2 class="button">{{ title }}</h2>
        </div>
        <div class="details text-center">
          <p class="blue_o">{{ description }}</p>
        </div>
      </div>
    </a>
  </v-card>
</template>

<script>
export default {
  name: "BookCard",
  props: {
    image: String,
    title: String,
    description: String,
    bookLink: String,
  },
  data() {
    return {
      path: "",
    };
  },
  mounted() {
    this.path = process.env.VUE_APP_URL;
  },
};
</script>

<style>
@import "@/assets/css/style.css";
</style>
