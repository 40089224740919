<template>
  <v-card>
    <v-app-bar
      height="90"
      app
      dark
      class="elevation-0 pl-14 navbar d-flex justify-space-between"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="d-flex d-sm-none"
      ></v-app-bar-nav-icon>
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2 ml-16"
        contain
        src="@/assets/images/tesol_syria_white.svg"
        transition="scale-transition"
        width="50"
      />
      <v-tabs v-model="tab" class="d-none d-sm-flex justify-end pr-16">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab v-for="link in links" :key="link.id" :to="link.src">
          {{ link.name }}
        </v-tab>
      </v-tabs>
    </v-app-bar>

    <v-navigation-drawer
      class="navbar"
      v-model="drawer"
      left
      floating
      fixed
      temporary
    >
      <v-list nav dense>
        <v-list-item-group v-model="group" color="white">
          <v-list-item
            v-for="link in links"
            :key="link.id"
            :to="link.src"
            class="white--text"
          >
            <v-list-item-content>{{ link.name }}</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    tab: null,
    links: [
      { name: "Home", src: "/" },
      { name: "About", src: "/about" },
      { name: "Board members", src: "/members" },
      { name: "Conferences", src: "/conferences" },
      { name: "Newsletter", src: "/newsletter" },
      { name: "TESOL Around", src: "/tesolaround" },
      { name: "Library", src: "/library" },
      { name: "Become A member", src: "/member" },
      { name: "Gallery", src: "/gallery" },
      { name: "News", src: "/news" },
    ],
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    bar() {
      return !this.drawer;
    },
  },
};
</script>
<style>
@import "../assets/css/navbar.css";
@import "../assets/css/style.css";
</style>
