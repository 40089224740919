import { HTTP } from "@/plugins/http";
const state = {
  Configs: [],
};
const getters = {
  ConfigsList: (state) => state.Configs,
};
const actions = {
  async fetchConfig({ commit }) {
    // const response = await HTTP.get("/configs");
    // commit("setConfig", response.data.data);
    // console.log(response.data.data);
    return new Promise((resolve, reject) => {
      // Make an asynchronous call to fetch data
      HTTP.get("/configs")
        .then((response) => {
          commit("setConfig", response.data.data);
          console.log(response.data.data);
          // Update the state with the fetched data
          state.data = response.data.data;
          // Resolve the Promise with the fetched data
          resolve(state.data);
        })
        .catch((error) => {
          // Reject the Promise with the error message
          reject(error.message);
        });
    });
  },
};
const mutations = {
  setConfig: (state, Config) => (state.Config = Config),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
