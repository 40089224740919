import { HTTP } from "@/plugins/http";
const state = {
  lectures: [],
};
const getters = {
  lecturesList: (state) => state.lectures,
};
const actions = {
  async fetchLectures({ commit }, id) {
    const response = await HTTP.get("/lectures", {
      params: { conference_id: id },
    });
    commit("setLectures", response.data.data); //remove the 2nd data in json server
  },
};
const mutations = {
  setLectures: (state, lectures) => (state.lectures = lectures),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
